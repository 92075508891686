<template>
  <div>
    <!-- 号卡激活员 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.userName" clearable placeholder="请输入名称进行查询" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="repairTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="repairTotal>0"
        :data="repaList"
        :total="repairTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="extend.username" show-overflow-tooltip label="姓名"></el-table-column>
        <el-table-column sortable="custom" prop="extend.mobile" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column sortable="custom" prop="extend.passport" show-overflow-tooltip label="账号"></el-table-column>
        <el-table-column sortable="custom" prop="" show-overflow-tooltip label="管辖区域">
          <template slot-scope="scope">
            <span>{{scope.row.schoolName}}-{{scope.row.distName}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button  size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button  size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑用户' : '添加用户'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false" :before-close="colse">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="form.mobile" @change="contant" clearable :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="userName">
          <el-input v-model="form.userName" clearable disabled></el-input>
        </el-form-item>
        <el-form-item prop="type" label="成员类型：">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">管理员</el-radio>
            <el-radio :label="2">成员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="管辖区域：" prop="schoolId">
          <el-select clearable filterable v-model="form.distId" @change="areaChange">
            <el-option v-for="(item,index) in schoolOptions" :label="item.fullName" :value="item.distId" :key="index"></el-option>
          </el-select>
          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="colse">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'memberManagement',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, provinces],
  data() {
    return {
      queryParams: {
        roleType:3,
      },
      schoolOptions:[],
      userRoleIds:[],
      roleOptions:[],
      form:{
        roleType:3,
        userId:'',
        mobile:'',
        schoolId:'',
        distId:'',
        type:'',
      },
      formRules:{
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        teamUserType: [{ required: true, message: '请选择团队角色', trigger: 'blur' }],
        teamUserType: [{ required: true, message: '请选择管辖区域', trigger: 'blur' }],
      },
      pageApi:'repairList',
      insertApi:'repairAdd',
      editorApi:'repairEdit',
      deleteApi:'repairDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("fuseUser", ["repaList", "repairTotal"]),
  },
  methods: {
    
    ...mapActions("fuseUser",["repairList", "getRepairUser", "repairAdd", "repairDelete","repairEdit","repairGetSchool"]),
    areaChange(val) {
      this.form.distId = val
      this.form.schoolId = this.schoolOptions.find(v=>v.distId == val).schoolId
      // console.log(this.schoolOptions.find(v=>v.distId == val))
    },
    colse() {
      this.$refs.formFileds.resetFields();
      this.aVisible = false;
      this.form.distId = ''
    },

    handleForm(isEditor = false, row, afterFun = null) {
      this.aVisible = true;
      this.isEditor = isEditor
      this.$nextTick(async () => {
        this.$refs.formFileds.resetFields();
        if (isEditor) {
          mergeObject(this.form,row)
          this.form.userName = row.extend.username
          this.form.mobile = row.extend.mobile
        }
      })
    },
    async submitForm(api) {
      let params = Object.assign({}, this.form)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              await this[api](params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
              this.colse()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    async contant(val) {
      this.form.userName = ''
      try{
        const res = await this.getRepairUser({mobile:val})
        console.log(res)
        this.form.userName = res.extend.userName
        this.form.userId = res.id
      }catch(e){
      }
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.schoolOptions  =  await this.repairGetSchool({})
  }
};
</script>